<template>
  <div>
    <InlineMessageBox header="Important Upgrade Information" site="buyer">
      <ul>
        <div v-for="(item, index) in bulletPoint.items" :key="index">
          <li>
            {{ item }}
          </li>
        </div>
      </ul>
    </InlineMessageBox>

    <processing-modal
      :active="isProcessing"
      :alrtMsg="'Processing...'"
      :isCancel="false"
    />
    <div class="formulateForm">
      <FormulateForm
        name="upgradeForm"
        v-model="formData"
        @submit="submit"
        #default="{ hasErrors }"
        autocomplete="false"
      >
        <div class="repeatable-group-wrapper">
          <div
            :class="isClassName"
            v-for="(repeat, index) in repeatableObject"
            :key="index"
          >
            <div class="row" v-show="isAddTicket">
              <div class="col half">
                <FormulateInput
                  v-model="repeatableObject[index].current"
                  type="select"
                  :options="orderOrTicketList"
                  :label="currentDisplayLabel"
                  placeholder="- Please Select -"
                  :error="currentErrorMessageDisplay(index)"
                  @input="getCurrentTicketValue(index)"
                  ignored
                  :disabled="changesConfirmed"
                  autocomplete="false"
                />
              </div>

              <div class="col half">
                <FormulateInput
                  v-model="repeatableObject[index].upgrade"
                  type="select"
                  :options="repeatableObject[index].option"
                  :label="upgradeDisplayLabel"
                  placeholder="Event Name - Tier Name $X.XX"
                  @input="getPrice(index)"
                  ignored
                  :disabled="changesConfirmed"
                  autocomplete="false"
                />
              </div>
            </div>

            <div class="row" v-show="isRemoveButton">
              <div class="remove-button-container">
                <div class="remove-button" @click="removeObj(index)">--</div>
              </div>
            </div>
          </div>

          <div class="row" v-show="isAddTicketOrder">
            <div class="add-button" @click="addObj">+ Add Another Ticket</div>
          </div>
        </div>

        <div class="right-billing-info-inner-container">
          <div class="row">
            <div class="col half">
              <p>ORIGINAL</p>
            </div>
            <div class="col half">
              <Card :class="strikeThroughStyle">
                {{ currencySign }}{{ calculateOldTotal() }}
              </Card>
            </div>
          </div>

          <div class="row">
            <div class="col half">
              <p>UPGRADE</p>
            </div>
            <div class="col half">
              <Card> {{ currencySign }}{{ calculateNewTotal() }} </Card>
            </div>
          </div>

          <div>Difference:</div>
          <div class="col full totalArea">
            <Card class="total">
              <p>
                Total:
                <span>{{ currencySign }}{{ calculateDifference() }}</span>
              </p>
            </Card>
          </div>
        </div>

        <FormulateInput
          type="button"
          class="submit-button"
          name="Confirm"
          @click="checkout"
          :loading="loading"
          :disabled="isPurchase(hasErrors)"
        />
      </FormulateForm>
    </div>
    <div class="divider"></div>
  </div>
</template>
<script>
import BulletPointBox from "@/components/BulletPointBox.vue";
import CheckoutTimer from "@/components/CheckoutTimer";
import InlineMessageBox from "@/components/InlineMessageBox.vue";
import ProcessingModal from "@/components/modals/ProcessingModal.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import dateFormat from "dateformat";
import dayjs from "dayjs";
import LocalizedFormats from "dayjs/plugin/localizedFormat";
import Cookies from "universal-cookie";

dayjs.extend(LocalizedFormats);
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);
function getMaxQuantity(tier, venueAccessDate, venueAccessTime) {
  if (venueAccessTime) {
    return venueAccessTime.qty;
  }
  if (venueAccessDate) {
    return venueAccessDate.qty;
  }
  return tier.qty - tier.start + 1;
}
library.add(faTrash);
const TITLE = "Important Upgrade Information";
const ITEM01 =
  "When upgrading your ticket you will be charged the difference between the original and your upgrade.";
const ITEM02 =
  "Your original ticket will be returned to the event for someone else to purchase.";
const ITEM03 =
  "Your original ticket will no longer be scannable or valid for registration.";
const ITEM04 = "Taxes and fair fees will be calculated on checkout.";
const ITEM05 =
  "If you can't select the options below you have not purchased tickets to any events which offer this feature.";
const ORDER = "Order";
const TICKET = "Ticket";
const CURRENT = "Current";
const CURRENT_ORDER = "Current Order";
const CURRENT_TICKET = "Current Ticket";
const UPGRADE = "Upgrade";
const NEW_ORDER = "New Order";
const NEW_TICKET = "New Ticket";
function getCartItem(cart, tier, venueAccessDate, venueAccessTime) {
  if (venueAccessTime) {
    return cart.find((t) => t.venueAccessTimeId === venueAccessTime.id);
  }
  if (venueAccessDate) {
    return cart.find((t) => t.venueAccessDateId === venueAccessDate.id);
  }
  return cart.find((t) => t.tierId === tier.saleDateId);
}
export default {
  name: "upgrade",
  head() {
    return {
      title: "Upgrades",
    };
  },
  components: {
    BulletPointBox,
    FontAwesomeIcon,
    CheckoutTimer,
    ProcessingModal,
    InlineMessageBox,
  },
  data() {
    return {
      bulletPoint: {
        title: TITLE,
        items: [ITEM01, ITEM02, ITEM03, ITEM04, ITEM05],
      },
      orderOrTicket: TICKET,
      repeatableObject: [
        {
          current: null,
          upgrade: null,
          option: [],
          newTotal: 0,
          oldTotal: 0,
          isUpgradeAvailable: false,
          tierPrice: 0,
          tierCount: 0,
        },
      ],
      formData: {},
      billingData: {},
      orderList: [],
      ticketList: [],
      eventTierList: null,
      orderOrTicketOption: [ORDER, TICKET],
      isRemoveObj: false,
      orderListXX: [],
      currencySign: "$",
      confirmModalActive: true,
      changesConfirmed: false,
      event: null,
      loading: false,
      isProcessing: false,
    };
  },
  computed: {
    canCheckout() {
      return true;
      this.$store.getters.getSelectedTickets(this.event.url).some((o) => o);
    },
    strikeThroughStyle() {
      return parseFloat(this.calculateOldTotal()) === 0
        ? ""
        : "old-total-price";
    },
    isClassName() {
      return this.repeatableObject.length > 1
        ? "repeatable-group-container"
        : "repeatable-group-container2";
    },
    orderOrTicketList() {
      if (this.orderOrTicket === ORDER) {
        return this.orderList;
      } else if (this.orderOrTicket === TICKET) {
        return this.ticketList;
      } else {
        return [];
      }
    },
    currentDisplayLabel() {
      return this.orderOrTicket === ORDER
        ? CURRENT_ORDER
        : this.orderOrTicket === TICKET
        ? CURRENT_TICKET
        : CURRENT;
    },
    upgradeDisplayLabel() {
      return this.orderOrTicket === ORDER
        ? NEW_ORDER
        : this.orderOrTicket === TICKET
        ? NEW_TICKET
        : UPGRADE;
    },
    isAddTicket() {
      return this.orderOrTicket !== null ? true : false;
    },
    isAddTicketOrder() {
      return this.orderOrTicket === null
        ? false
        : this.orderOrTicket === ORDER
        ? false
        : true;
    },
    isRemoveButton() {
      return this.repeatableObject.length > 1 ? true : false;
    },
  },
  methods: {
    checkout() {
      this.loading = true;
      this.changesConfirmed = true;
      this.isProcessing = true;
      let eventObj = this.eventTierList.find(
        (et) => et._id === this.repeatableObject[0].current[1].orderEventId
      );
      let cartTickets = [];
      this.$axios.get("/event/" + eventObj.url).then(({ data: event }) => {
        this.repeatableObject.forEach((ticket) => {
          let upgradeTier = ticket.upgrade[1].tier;
          let saleDateId = ticket.upgrade[0];
          upgradeTier.saleDateId = saleDateId;
          upgradeTier.price =
            parseFloat(ticket.newTotal) - parseFloat(ticket.oldTotal);
          let tierSaleDate = upgradeTier.saleDates.find(
            (x) => saleDateId === x.id
          );
          this.event = event;
          cartTickets.push(ticket);
          this.addToCart(upgradeTier, tierSaleDate);
        });

        this.$store.state.toteUpgrade = {
          type: this.orderOrTicket,
          tickets: cartTickets,
        };
        setTimeout(() => {
          this.purchase();
        }, 3000);
      });
    },
    addToCart(tier, venueAccessDate, venueAccessTime) {
      const eventURL = this.event.url;
      const cart = this.$store.state.cart;
      const item = getCartItem(cart, tier, venueAccessDate, venueAccessTime);
      const ccfee = tier.absorb ? 0 : tier.ccfee;
      const dfee = tier.absorb ? 0 : tier.dfee;
      let name = tier.name;
      if (venueAccessTime) {
        name +=
          " " + dayjs(venueAccessTime.start).format("(MMM DD, YYYY h:mm A)");
      } else if (venueAccessDate) {
        const startDate = this.formatDate(
          venueAccessDate.sale_start.$date.$numberLong
        );
        name += " " + dayjs(startDate).format("(MMM DD, YYYY)");
      }
      let tierSaleDate = tier.saleDates.find((x) => tier.saleDateId === x.id);
      let limit = tierSaleDate.purchase_limit
        ? tierSaleDate.max_purchase
        : getMaxQuantity(tier, venueAccessDate, venueAccessTime);
      let min_purchase = tierSaleDate.purchase_limit
        ? tierSaleDate.min_purchase
        : 1;
      if (!item) {
        this.$store.state.cart.push({
          tier_id: tier.id,
          tierId: tier.saleDateId,
          saleDateId: tier.saleDateId,
          venueAccessDateId: venueAccessDate ? venueAccessDate.id : null,
          venueAccessTimeId: venueAccessTime ? venueAccessTime.id : null,
          eventDateId: venueAccessDate ? venueAccessDate.event_date_id : null,
          indexId: venueAccessTime
            ? venueAccessTime.id
            : venueAccessDate
            ? venueAccessDate.id
            : tier.saleDateId,
          tierName: name,
          description: tier.description,
          type: tier.type,
          order: min_purchase,
          upgrade: true,
          eventId: this.event.id,
          price: tier.price,
          url: eventURL,
          addfee: tier.addfee,
          ccfee: ccfee,
          dfee: dfee,
          absorb: tier.absorb,
          limit: limit,
          minPurchase: min_purchase,
          // index: index,
        });
        // this.inputArr[index] = min_purchase;
      } else {
        let maxQty = getMaxQuantity(tier, venueAccessDate, venueAccessTime);
        let tierSaleDate = tier.saleDates.find((x) => tier.saleDateId === x.id);
        if (tierSaleDate.purchase_limit) {
          item.order =
            item.order >= tierSaleDate.max_purchase ||
            item.order + min_purchase >= tierSaleDate.max_purchase
              ? tierSaleDate.max_purchase
              : item.order + min_purchase;
          // this.inputArr[index] = item.order;
        } else {
          item.order =
            item.order >= maxQty ? maxQty : item.order + min_purchase;
          // this.inputArr[index] = item.order;
        }
      }
      this.$forceUpdate();
    },
    async purchase() {
      const orders = this.$store.getters.getSelectedTickets(this.event.url);
      const products = this.$store.getters.getSelectedProducts(this.event.url);
      if (orders.length > 0 || products.length > 0) {
        const cookies = new Cookies();
        const cartID = cookies.get(`${this.event.url}-cart`);
        await this.$axios
          .post("/cart/update", {
            orders: orders,
            products,
            event: this.event.url,
            cart: cartID,
          })
          .then((response) => {
            if (response.status === 200) {
              const data = response.data;
              const cart = data.cart;
              const expires = dayjs(+cart.created_at)
                .add(60, "minutes")
                .toDate();
              cookies.set(`${this.event.url}-cart`, cart.id, { expires });
              // cookies.set(`${this.event.url}`, expires.getTime(), { expires });
              this.isProcessing = false;
              this.$router.push({
                path: `/${this.event.url}/checkout/account-information`,
              });
            } else {
              this.loading = false;
            }
          });
      }
    },
    calculateOldTotal() {
      let sum = 0;
      for (const obj of this.repeatableObject) {
        if (obj.upgrade !== null) {
          sum = sum + parseFloat(obj.oldTotal);
        }
      }
      return sum.toFixed(2);
    },
    calculateNewTotal() {
      let sum = 0;
      for (const obj of this.repeatableObject) {
        if (obj.upgrade !== null) {
          sum += parseFloat(obj.newTotal);
        }
      }
      return sum.toFixed(2);
    },
    calculateTaxes() {
      // TODO : Do calculation in backend
      let sum = 0;
      for (const obj of this.repeatableObject) {
        if (obj.upgrade === null) {
          continue;
        } else {
          sum += obj.tierPrice * obj.tierCount;
        }
      }
      // return (parseFloat(this.calculateNewTotal()) - sum).toFixed(2);
      return "0.00";
    },
    calculateDifference() {
      return (
        parseFloat(this.calculateNewTotal()) -
        parseFloat(this.calculateOldTotal())
      ).toFixed(2);
    },
    async getPrice(index) {
      if (this.repeatableObject[index].upgrade !== null) {
        try {
          let orderId =
            this.orderOrTicket === ORDER
              ? this.repeatableObject[index].current[0]
              : this.repeatableObject[index].current[1].orderId;
          let saleDateId = this.repeatableObject[index].upgrade[0];
          let upgradeObj = {};
          if (this.orderOrTicket === ORDER) {
            upgradeObj = {
              type: this.orderOrTicket,
              order: {
                _id: orderId,
              },
              upgradeOrder: {
                id: saleDateId,
              },
            };
          } else {
            let ticket_id = this.repeatableObject[index].current[1].orderTicket
              .validation;
            upgradeObj = {
              type: this.orderOrTicket,
              order: {
                _id: orderId,
                ticket_id: ticket_id,
              },
              upgradeOrder: {
                id: saleDateId,
              },
            };
          }
          const { data } = await this.$axios.post(
            "/toteswap/calculate",
            upgradeObj
          );
          this.repeatableObject[index].newTotal = data.newTotal;
          this.repeatableObject[index].oldTotal = data.oldTotal;
          this.repeatableObject[index].tierPrice = this.repeatableObject[
            index
          ].upgrade[1].tierPrice;
          this.repeatableObject[index].tierCount = this.repeatableObject[
            index
          ].upgrade[1].tierCount;
        } catch (e) {
          console.log(e);
        }
      }
    },
    isPurchase(hasErrors) {
      if (hasErrors || this.orderOrTicket === null) {
        return true;
      } else {
        for (const obj of this.repeatableObject) {
          if (obj.current === null || obj.upgrade === null) {
            return true;
          }
        }
        return false;
      }
    },
    currentErrorMessageDisplay(index) {
      if (this.repeatableObject[index].isUpgradeAvailable) {
        if (this.repeatableObject[index].option.length > 0) {
          this.repeatableObject[index].isUpgradeAvailable = false;
          return "";
        } else {
          return "Sorry, there are no other ticket tiers available to upgrade.";
        }
      }
    },
    filterTierForOrder(orderTierList, tier) {
      let tierList = [];
      for (const orderTier of orderTierList) {
        tierList.push({ id: orderTier.tier_id, price: orderTier.price });
      }
    },
    async submit() {
      this.clearFormData();
    },
    getOrderOrTicketValue() {
      if (this.orderOrTicket !== null) {
        this.repeatableObject = [
          {
            current: null,
            upgrade: null,
            option: [],
            newTotal: 0,
            oldTotal: 0,
            isUpgradeAvailable: false,
            tierPrice: 0,
            tierCount: 0,
          },
        ];
        this.isRemoveObj = true;
      }
    },
    formatDate(date) {
      return dateFormat(new Date(parseInt(date)), "mm/dd/yy");
    },
    async getCurrentTicketValue(index) {
      if (this.isRemoveObj) {
        this.isRthis.repeatableObjectemoveObj = false;
      } else {
        try {
          let orderEventId =
            this.repeatableObject[index].current === undefined ||
            this.repeatableObject[index].current === null
              ? null
              : this.repeatableObject[index].current[1].orderEventId;
          if (orderEventId !== null) {
            let event = this.eventTierList.find((x) => x._id === orderEventId);
            let tempArr = [];
            for (const tier of event.tiers) {
              if (this.orderOrTicket === ORDER) {
                let orders = this.repeatableObject[index].current[1];
                let orderTierList = orders.orderTicketList;
                let maxPrice = 0;
                let orderTierId = null;
                for (const orderTier of orderTierList) {
                  if (parseFloat(orderTier.price.$numberDecimal) > maxPrice) {
                    maxPrice = parseFloat(orderTier.price.$numberDecimal);
                    orderTierId = orderTier.tier_id;
                  }
                }
                if (tier.id !== orderTierId && tier.price > maxPrice) {
                  for (const saleDate of tier.saleDates) {
                    if (saleDate.available <= 0) {
                      continue;
                    }
                    if (saleDate.soldout) {
                      continue;
                    }
                    const startDate = this.formatDate(
                      saleDate.sale_start.$date.$numberLong
                    );
                    const endDate = this.formatDate(
                      saleDate.sale_end.$date.$numberLong
                    );
                    tempArr.push({
                      label:
                        event.name +
                        tier.name +
                        `  (${this.currencySign}` +
                        tier.price.toFixed(2) +
                        ")",
                      value: [
                        saleDate.id,
                        {
                          tiedId: tier.id,
                          tier: tier,
                          tierPrice: tier.price,
                          tierCount: orders.orderCount,
                        },
                      ],
                    });
                  }
                }
              } else {
                let orderTier = this.repeatableObject[index].current[1]
                  .orderTicket;
                if (
                  orderTier.tier_id !== tier.id &&
                  tier.price > parseFloat(orderTier.price.$numberDecimal)
                ) {
                  for (const saleDate of tier.saleDates) {
                    if (saleDate.available <= 0) {
                      continue;
                    }
                    if (saleDate.soldout) {
                      continue;
                    }
                    const startDate = this.formatDate(
                      saleDate.sale_start.$date.$numberLong
                    );
                    const endDate = this.formatDate(
                      saleDate.sale_end.$date.$numberLong
                    );
                    tempArr.push({
                      label:
                        event.name +
                        " - " +
                        tier.name +
                        `  ${this.currencySign}` +
                        tier.price.toFixed(2),
                      value: [
                        saleDate.id,
                        {
                          tiedId: tier.id,
                          tier: tier,
                          tierPrice: tier.price,
                          tierCount: 1,
                        },
                      ],
                    });
                  }
                }
              }
            }
            this.repeatableObject[index].upgrade = null;
            this.repeatableObject[index].isUpgradeAvailable = true;
            this.repeatableObject[index].option = tempArr;
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    addObj() {
      this.repeatableObject.push({
        current: null,
        upgrade: null,
        option: [],
        newTotal: 0,
        oldTotal: 0,
        isUpgradeAvailable: false,
        tierPrice: 0,
        tierCount: 0,
      });
    },
    removeObj(index) {
      this.repeatableObject.splice(index, 1);
      this.isRemoveObj = true;
    },
    clearFormData() {
      this.repeatableObject = [
        {
          current: null,
          upgrade: null,
          option: [],
          newTotal: 0,
          oldTotal: 0,
          isUpgradeAvailable: false,
          tierPrice: 0,
          tierCount: 0,
        },
      ];
      this.orderOrTicket = TICKET;
      this.formData = {};
      this.$formulate.resetValidation("upgradeForm");
    },
    checkOrderListTier(orderTierList) {
      for (let i = 1; i < orderTierList.length; i++) {
        if (orderTierList[0].tier_id !== orderTierList[i].tier_id) {
          return false;
        }
      }
      return true;
    },
    autoCompleteAddress(address) {
      this.formData.streetNumber = address.street_number;
      this.formData.streetName = address.route;
      this.formData.city = address.locality;
      this.formData.state = address.administrative_area_level_2
        ? address.administrative_area_level_2
        : address.administrative_area_level_1;
      this.formData.country = address.country;
      this.formData.postalCode = address.postal_code;
    },
    getTierSaleDate(orderEventId, orderTierId, orderSaleDateId) {
      let eventTierList = this.eventTierList.find(
        (event) => event._id === orderEventId
      );
      let eventTier = eventTierList.tiers.find(
        (tier) => tier.id === orderTierId
      );

      return eventTier.saleDates.find(
        (tierSaleDate) => tierSaleDate.id === orderSaleDateId
      );
    },
  },
  async mounted() {
    this.$store.commit("setTitle", this.$metaInfo.title);
    try {
      const { data } = await this.$axios.post("/toteswap/get-orders", {
        plannerPolicy: "enable_upgrade_ticket",
        eventUrl: this.$route.params.url || '',
      });
      this.eventTierList = data.events;

      for (const order of data.orders) {
        console.log(order);
        let tempLabel = order.event_name + " - ";
        let tempValue = [
          order._id,
          {
            orderTicketList: order.tickets,
            orderCount: order.ticket_count,
            orderEventId: order.event_id,
          },
        ];

        let orderTier = order.tickets[0];
        if (!orderTier) {
          continue;
        }
        let eventTierSaleDate = this.getTierSaleDate(
          order.event_id,
          orderTier.tier_id,
          orderTier.saleDateId
        );
        let startDate = this.formatDate(
          eventTierSaleDate.sale_start.$date.$numberLong
        );
        let endDate = this.formatDate(
          eventTierSaleDate.sale_end.$date.$numberLong
        );
        for (let i = 0; i < order.tickets.length; i++) {
          let ticketObj = {
            label:
              order.event_name +
              " - " +
              order.tickets[i].tier +
              " (Ticket " +
              order.tickets[i].number +
              ")" +
              `  ${this.currencySign}` +
              Number(order.tickets[i].price.$numberDecimal).toFixed(2),
            value: [
              order.tickets[i].invoice,
              {
                orderId: order._id,
                orderTicket: order.tickets[i],
                orderEventId: order.event_id,
              },
            ],
          };
          this.ticketList.push(ticketObj);
          if (i === order.tickets.length - 1) {
            tempLabel =
              tempLabel +
              order.event_name +
              " - " +
              order.tickets[i].tier +
              " (Ticket " +
              order.tickets[i].number +
              ")" +
              `  ${this.currencySign}` +
              Number(order.tickets[i].price.$numberDecimal).toFixed(2);
          } else {
            tempLabel =
              tempLabel +
              order.event_name +
              " - " +
              order.tickets[i].tier +
              " (Ticket " +
              order.tickets[i].number +
              ")" +
              `  ${this.currencySign}` +
              Number(order.tickets[i].price.$numberDecimal).toFixed(2) +
              "|";
          }
        }
        let pcs = order.ticket_count === 1 ? "pc" : "pcs";
        tempLabel = tempLabel + " - " + order.ticket_count + pcs;
        this.orderList.push({ label: tempLabel, value: tempValue });
      }
      this.$store.dispatch("resetCart");
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
<style lang="less" scoped>
.right-billing-info-inner-container {
  display: grid;
  gap: 16px;
  margin-bottom: 16px;
}

// .formulate-input[data-classification="button"] button,
// .formulate-input[data-classification="submit"] button {
//   margin-left: auto;
// }

#Content .content-inner .row {
  width: 100%;
}
.repeatable-group-wrapper {
  margin: auto auto 20px auto;
  .repeatable-group-container {
    width: 100%; //2
    display: grid;
    place-items: center;
    grid-template-columns: 1fr 0.05fr;
  }
  .repeatable-group-container2 {
    width: 100%;
    display: grid;
    place-items: center;
  }
  .remove-button-container {
    background: #2bacd9;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin: auto;
  }
  .remove-button {
    cursor: pointer;
    -webkit-text-stroke-width: thick;
    font-weight: bolder;
    color: #222429;
    text-align: center;
  }
  .add-button {
    width: 210px;
    margin: 10px auto;
    padding: 8px 16px;
    border: 1px px solid var(--border);
    color: var(--primary-green);
    background: var(--input-background);
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    border: 1px solid var(--border);
  }
}

.billing-info-container,
.credit-card-container,
.right-credit-card-inner-container,
.billing-address-container {
  display: grid;
  gap: 16px;
}

.billing-info-container {
  grid-template-columns: 2.5fr 1fr;
  @media screen and (max-width: 600px) {
    display: block;
  }
  .left-billing-info-inner-container {
    .credit-card-container {
      grid-template-columns: 1.8fr 1fr;
      @media screen and (max-width: 950px) {
        display: block;
      }
    }

    .right-credit-card-inner-container {
      grid-template-columns: 1fr 1.5fr;
    }

    .billing-address-container {
      grid-template-columns: 2fr 1fr;
      @media screen and (max-width: 670px) {
        display: block;
      }
    }
  }

  .right-billing-info-inner-container {
    .row {
      margin-top: 16px;
      p {
        font-weight: 600;
        color: #44b678;
        text-align: right;
        font-size: 16px;
      }
      @media screen and (max-width: 670px) {
        flex-wrap: inherit;
      }
    }
    .timer {
      h2,
      p {
        color: white;
        margin: 5px 0 0 0;
      }

      h2 {
        font-size: 53px;
      }
      p {
        text-align: center;
        font-size: 19px;
      }

      // text-align: center;
      // height: 120px;
      // border: 1px pink solid;
      // border-radius: 10px;
      // background-color: #f1005a;
    }
    .card {
      font-weight: 600;
      text-align: center;
    }
    .old-total-price {
      color: red;
      text-decoration-line: line-through;
      text-decoration-thickness: 3px;
    }
    .totalArea {
      transform: scale(1.5, 1);
    }
    .total {
      margin-top: 8px;
      transform: scale(1.5, 1);
      height: 60px;
      p {
        font-size: 24px;
        font-weight: 600;
        text-align: center;
        margin: 8px 0 0 0;
      }
      span {
        color: #44b678;
      }
    }
    .submit-button {
      button {
        margin-top: 16px;
        width: 100%;
      }
    }
  }
}

.billing-info-container,
.credit-card-container,
.right-credit-card-inner-container,
.billing-address-container {
  display: grid;
  gap: 16px;
}

.billing-info-container {
  grid-template-columns: 2.5fr 1fr;
  @media screen and (max-width: 600px) {
    display: block;
  }
  .left-billing-info-inner-container {
    .credit-card-container {
      grid-template-columns: 1.8fr 1fr;
      @media screen and (max-width: 950px) {
        display: block;
      }
    }

    .right-credit-card-inner-container {
      grid-template-columns: 1fr 1.5fr;
    }

    .billing-address-container {
      grid-template-columns: 2fr 1fr;
      @media screen and (max-width: 670px) {
        display: block;
      }
    }
  }

  .right-billing-info-inner-container {
    .row {
      margin-top: 16px;
      p {
        font-weight: 600;
        color: #44b678;
        text-align: right;
        font-size: 16px;
      }
      @media screen and (max-width: 670px) {
        flex-wrap: inherit;
      }
    }
    .timer {
      h2,
      p {
        color: white;
        margin: 5px 0 0 0;
      }

      h2 {
        font-size: 53px;
      }
      p {
        text-align: center;
        font-size: 19px;
      }

      // text-align: center;
      // height: 120px;
      // border: 1px pink solid;
      // border-radius: 10px;
      // background-color: #f1005a;
    }
    .card {
      font-weight: 600;
      text-align: center;
    }
    .old-total-price {
      color: red;
      text-decoration-line: line-through;
      text-decoration-thickness: 3px;
    }

    .total {
      margin-top: 8px;
      height: 60px;
      margin-left: -40px;
      margin-right: -40px;

      // transform: scale(1.03, 1);
      p {
        font-size: 24px;
        font-weight: 600;
        text-align: center;
        margin: 8px 0 0 0;
      }
      span {
        color: #44b678;
      }
    }
    // .submit-button {
    //   button {
    //     margin-top: 16px;
    //     width: 100%;
    //   }
    // }
  }
}
</style>

<style lang="less">
.submit-button {
  text-align: -webkit-right;
  .formulate-input-element {
    button {
      background: #00b7f1;
    }
  }
}
</style>
