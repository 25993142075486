<template>
  <div id="Content">
    <div class="content-inner">
      <confirm-modal
        ref="upgradeConfirm"
        popUpTitleMsg="Are you sure?"
        @confirm-method="upgrade"
        :popUpMsg="customUpgradeMsg"
      />
      <confirm-modal
        ref="downgradeConfirm"
        popUpTitleMsg="Are you sure?"
        @confirm-method="downgrade"
        :popUpMsg="customDowngradeMsg"
      />
      <demo-modal ref="demoModal" />
      <div class="subscription-container">
        <div class="row">
          <div class="col flex half center">
            <h1>Subscription Plans</h1>
          </div>
        </div>
        <div class="row">
          <div class="col quarter">
            <div class="current-plan">
              <div class="top">
                <p>Current Plan</p>
              </div>
              <div class="body">
                <div class="body-text">
                  <div class="left">Price</div>
                  <div class="right">${{ billing_details.monthly_price }}</div>
                </div>
                <div class="body-text">
                  <div class="left">Billing Period</div>
                  <div class="right">{{ billing_details.period }}</div>
                </div>
                <div class="body-text">
                  <div class="left">Status</div>
                  <div class="right">
                    {{ billing_details.status }}
                  </div>
                </div>
                <div class="body-text">
                  <div class="left">{{ billing_details.renew_text }}</div>
                  <div class="right">
                    {{ formatDate(billing_details.renew_date) }}
                  </div>
                </div>
              </div>
              <div class="top">
                <p>Plan Features</p>
              </div>
              <div class="plan-body body">
                <div class="features">
                  <div
                    class="feature"
                    v-for="(feature, index) in features"
                    :key="index"
                  >
                    <font-awesome-icon icon="check" class="icon" />
                    {{ feature }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col quarterx3">
            <div class="plans">
              <div class="plan" v-for="(plan, index) in plans" :key="index">
                <div class="top">
                  <div class="name">{{ plan.name }}</div>
                  <div class="current" v-if="plan.current">
                    Your Current Plan
                  </div>
                </div>

                <div class="body" :class="{ current: plan.current }">
                  <span class="description">{{ plan.description }}</span>
                  <span class="price">
                    ${{ plan.price }}
                    <span class="upgrade" v-if="plan.demo" @click="demo">
                      Contact
                    </span>
                    <span
                      class="upgrade"
                      @click="upgradeConfirm(plan)"
                      v-if="plan.upgrade && !plan.current && !plan.demo"
                    >
                      Upgrade
                    </span>

                    <span
                      class="downgrade"
                      @click="downgradeConfirm(plan)"
                      v-else-if="!plan.upgrade && !plan.current"
                    >
                      Downgrade
                    </span>
                  </span>
                </div>
                <div class="divider"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faCheck,
  faCheckCircle,
  faLink
} from "@fortawesome/free-solid-svg-icons";
import ConfirmModal from "../../../components/modals/ConfirmModal.vue";
import DemoModal from "../../../components/modals/DemoModal.vue";

import dayjs from "dayjs";

library.add(faCheck);

export default {
  name: "active-subscription",
  components: {
    FontAwesomeIcon,
    ConfirmModal,
    DemoModal
  },
  data() {
    return {
      customUpgradeMsg: "",
      customDowngradeMsg: "",
      features: [
        "Access to all features",
        "Email only",
        "Upgrades to SMS available",
        "4% Additional transaction fee",
        "Top tier support"
      ],
      plan_credits_details: {
        total: "5000",
        used: 2000,
        available: 3000,
        last_updated: "01/04/23"
      },

      plans: [
        {
          name: "5,000",
          description: "5,000 bookings",
          bookings: "5,000",
          price: 74.99,
          id: "1",
          stripeId: "price_1QZOj0CtqkpO7frUeMZv5sHk",
          upgrade: false,
          current: false,
          features: ["New Features"]
        },
        {
          name: "10,000",
          description: "10,000 bookings",
          bookings: "10,000",
          price: 124.99,
          id: "2",
          current: false,
          upgrade: false,
          stripeId: "price_1QZPNOCtqkpO7frU5x7qtdDb"
        },
        {
          name: "25,000",
          description: "25,000 bookings",
          bookings: "25,000",
          price: 199.99,
          id: "3",
          upgrade: false,
          current: false,
          stripeId: "price_1QZPO3CtqkpO7frUZG4vLGEs"
        },
        {
          name: "50,000 +",
          description: "50,000 bookings",
          bookings: "50,000 +",
          price: 299.99,
          id: "4",
          demo: true,
          current: false,
          upgrade: false,
          stripeId: ""
        }
      ],

      plan_upgrade_id: "",
      plan_downgrade_id: "",
      billing_details: {
        amount: 50.0,
        period: "Monthly",
        renew_date: "Jun 15, 2023"
      }
    };
  },
  computed: {
    brandUrl() {
      return this.$route.params.subdomain;
    }
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format("MMM DD, YYYY");
    },
    upgradeConfirm(plan) {
      this.customUpgradeMsg = `You're upgrading your guest list subscription to ${plan.bookings} bookings a month. You'll be upgraded immediately to make sure your guest list is running with maximum capacity.`;
      this.plan_upgrade_id = plan.stripeId;
      this.$refs.upgradeConfirm.open();
    },
    upgrade() {
      this.$axios
        .post(`/subscriptions/upgrade/`, {
          brandUrl: this.brandUrl,
          priceId: this.plan_upgrade_id
        })
        .then(({ data }) => {
          window.location.reload();

          this.$toast.success(
            `Your subscription for this brand has been upgraded.`
          );
        });
    },
    downgradeConfirm(plan) {
      this.customDowngradeMsg = `You're downgrading your guest list subscription to ${plan.bookings} bookings a month. You'll be downgraded at the end of billing period.`;
      this.plan_downgrade_id = plan.stripeId;
      this.$refs.downgradeConfirm.open();
    },
    downgrade() {
      this.$axios
        .post(`/subscriptions/downgrade/`, {
          brandUrl: this.brandUrl,
          priceId: this.plan_downgrade_id
        })
        .then(({ data }) => {
          window.location.reload();

          this.$toast.success(
            `Your subscription for this brand has been downgraded.`
          );
        })
        .catch(error => {
          if (error) {
            this.$bus.$emit("alert", {
              title: "Error",
              msg:
                error.response.data ||
                "An unknown error occurred. Please try again later."
            });
          }
        });
    },
    demo() {
      this.$refs.demoModal.open();
    }
  },
  created() {
    this.$axios
      .get(`/subscriptions/get-guestlist-subscription/${this.brandUrl}`)
      .then(({ data }) => {
        this.billing_details = {
          ...data.invoices[0],
          period: "Monthly",
          renew_date: data.plan.end_date,
          name: data.plan.name,
          status: data.plan.status,
          renew_text: data.plan.renew_text,
          monthly_price: data.plan.price
        };

        let currentPlan = null;
        this.plans.forEach(plan => {
          if (plan.stripeId === data.plan.stripe_price_id) {
            plan.current = true;
            currentPlan = plan;
          }
        });
        this.plans.forEach(plan => {
          if (plan.price < currentPlan.price) {
            plan.upgrade = false;
          } else {
            plan.upgrade = true;
          }
        });
      });
  },
  mounted() {}
};
</script>

<style lang="less" scoped>
.current-plan {
  background: var(--content-light);
  padding: 16px;
  border-radius: 8px;

  .top {
    p {
      margin: 0;
      font-weight: 600;
      font-size: 20px;
    }
  }
  .body {
    margin: 16px 0;
  }

  .features {
    .feature {
      font-size: 14px;
      color: var(--text-secondary);
      margin-bottom: 8px;
    }

    .icon {
      color: var(--primary);
      border: 1px solid;
      padding: 3px;
      border-radius: 50%;
      margin-right: 8px;
    }
  }
}

.plan {
  .top {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    // margin: 16px 0;
    .name {
      margin: 16px 0;
      margin-top: 0;
      font-weight: 600;
      font-size: 20px;
    }
    .current {
      display: flex;
      align-items: center;
      padding: 6px 12px;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: bold;
      border-radius: 20px;
      border: 1px solid var(--primary);
      background: var(--content-background-dark);
    }
  }

  .body {
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    padding: 16px;
    background: var(--content-light);

    &.current {
      border: 1px solid var(--primary);
      background: var(--content-background-dark);
    }

    .price {
      display: flex;
      align-items: center;
      column-gap: 16px;
    }
    .upgrade,
    .downgrade {
      cursor: pointer;
      padding: 4px 10px;
      font-size: 14px;
      font-weight: bold;
      border-radius: 20px;

      background: linear-gradient(
        60deg,
        var(--primary-green) 40%,
        var(--secondary-green) 100%
      );
      transition: all 2s ease;

      &:hover {
        background: linear-gradient(
          60deg,
          var(--primary-green) 0%,
          var(--secondary-green) 100%
        );
      }
    }
  }
}
.body-text {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  .left {
    color: var(--text-secondary);
  }
}
</style>
