<template>
  <div class="embed-settings" id="Content">
    <div class="landing-container">
      <h2>INCREDELIST Features</h2>
      <div class="feature-container">
        <div class="features-1">
          <div
            class="feature"
            v-for="(feature, index) in features1"
            :key="index"
          >
            {{ feature }}
          </div>
        </div>
        <div class="features-2">
          <div
            class="feature"
            v-for="(feature, index) in features2"
            :key="index"
          >
            {{ feature }}
          </div>
        </div>
        <div class="features-3">
          <div
            class="feature"
            v-for="(feature, index) in features3"
            :key="index"
          >
            {{ feature }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col flex half center">
          <FormulateInput
            type="button"
            name="View Complete List of features"
            @click="scrollToList"
          />
        </div>
      </div>
    </div>
    <div class="content-inner">
      <div class="divider"></div>
    </div>
    <div class="pricing-plan-container">
      <div
        v-for="(pricing, index) in pricingPlans"
        :key="index"
        :class="{ 'most-popular': pricing.popular }"
      >
        <p v-if="pricing.popular">Most Popular</p>

        <subscription-tile :subscription="pricing" />
      </div>
    </div>
    <div class="content-inner">
      <div class="divider"></div>
      <section id="completeFeature">
        <div class="complete-feature-container">
          <div class="row">
            <div class="col flex half center">
              <h2>Complete List of Features</h2>
            </div>
          </div>
          <div class="row">
            <div class="col half">
              <Card>
                <ul>
                  <li v-for="(feature, index) in guestlist_vip" :key="index">
                    {{ feature }}
                  </li>
                </ul>
              </Card>
            </div>
            <div class="col half">
              <Card>
                <ul>
                  <li v-for="(feature, index) in guestlist_vip2" :key="index">
                    {{ feature }}
                  </li>
                </ul>
              </Card>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SubscriptionTile from "../../../components/brand/SubscriptionTile.vue";

export default {
  components: { SubscriptionTile },
  name: "subscription",

  head() {
    return {
      title: "Pricing Plan - Guest List",
      script: [
        {
          src: "https://js.stripe.com/v3/"
        }
      ]
    };
  },
  data() {
    return {
      features1: [
        "Unlimited Events",
        "AI Learns customer habits",
        "Streamline Front of House Design"
      ],
      features2: [
        "pre-paid sections, bottles & more",
        "Embed Guest list anywhere on web",
        "Own Your Customer Data"
      ],
      features3: [
        "Collect Birthday Information",
        "re-sell & up-sell past customers",
        "Any POS Integration with an open API"
      ],
      pricingPlans: [
        {
          bookings: "5,000",
          price: "74.99",
          id: "1",
          stripeId: "price_1QZOj0CtqkpO7frUeMZv5sHk",
          trial: true,
          credits: 5000
        },
        {
          bookings: "10,000",
          price: "124.99",
          id: "2",
          stripeId: "price_1QZPNOCtqkpO7frU5x7qtdDb",
          popular: true,
          credits: 10000
        },
        {
          bookings: "25,000",
          price: "199.99",
          id: "3",
          stripeId: "price_1QZPO3CtqkpO7frUZG4vLGEs",
          credits: 25000
        },
        {
          bookings: "25,000 +",
          price: "299.99",
          id: "4",
          demo: true,
          stripeId: "",
          credits: 25000
        }
      ],
      guestlist_vip: [
        "International payment processing",
        "Public and private events",
        "Unlimited events",
        "Guest lists",
        "Pre-purchase bottles",
        "Pre-purchase food",
        "Pre-purchase sections",
        "Reserved sections",
        "Recurring events for easy setup",
        "Birthday integrations",
        "Offer as an up-sell on ticketed events",
        "Saved menu flow",
        "Expected vs Actual attendees",
        "Tie VIP to promoters and staff for credit",
        "Rankings to identify MVP VIPs",
        "Denied list",
        "Wait list",
        "White labelling",
        "Manual or auto-approvals",
        "AI based segmentation",
        "Use our email CRM"
      ],
      guestlist_vip2: [
        "System notifications",
        "Easy check-in",
        "Email automations",
        "Embed the form anywhere",
        "Financial tracking",
        "Control group sizes",
        "Control access times",
        "Apply auto-grats",
        "Put your rules front and center",
        "Create packages",
        "Leave internal notes",
        "Edit reservations on the fly",
        "Track ALL data",
        "CSV downloads",
        "Real time data like check-ins & purchase summaries",
        "3rd party processors",
        "Integration with PatronScan coming soon",
        "& MORE FEATURES RELEASED REGULARLY!"
      ],
      stripe: ""
    };
  },
  computed: {
    brandUrl() {
      return this.$route.params.subdomain;
    }
  },
  methods: {
    scrollToList() {
      document.getElementById("completeFeature").scrollIntoView();
    }
  },
  async created() {
    const { data } = await this.$axios.get(
      `/subscriptions/read-guestlist-subscription/${this.brandUrl}`
    );

    if (data.hasSubscription) {
      this.$router.push("active");
    }
  },
  async mounted() {
    try {
      // Find and set user brands active subscription
      // await this.getOrder();
      // this.initialize();
    } catch (error) {
      console.log(error);
    }
  }
  // beforeEnter: (to, from, next) => {
  //   console.clear();
  //   next(() => {
  //     console.log("Hello");
  //   });

  //   // const { data: hasSubscription } = axios.get(
  //   //   "/subscriptions/read-guestlist-subscription"
  //   // );
  //   // console.log(hasSubscription);

  //   // if (hasSubscription) {
  //   //   console.log(to, from, next);
  //   // }
  // }
};
</script>

<style lang="less" scoped>
.content-inner {
  padding: 0 !important;
}
.landing-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(/img/placeholders/guestlist_queue.png);
  background-size: cover;
  padding: 0 20px 20px;
}

.feature-container {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  padding: 20px;

  .feature {
    font-size: 20px;
    font-weight: bold;
    margin: 16px 0px;
  }
}

.pricing-plan-container {
  justify-content: space-evenly;
  align-items: center;
  width: 90%;
  padding: 0 0 8px 0;
  margin: auto;
  display: flex;
  column-gap: 24px;
  row-gap: 24px;
  flex-wrap: wrap;

  .most-popular {
    background: var(--primary-green);
    padding: 1px;
    border-radius: 8px;

    > p {
      margin: 8px 0;
      font-size: 24px;
      text-align: center;
      color: black;
      font-weight: bold;
    }
    .plan {
      background: #2a2d33;

      ::v-deep .formulate-input {
        button {
          background: white;
        }
      }
    }
  }
}

.complete-feature-container {
  ul {
    padding-inline-start: 20px;

    li {
      margin: 8px 0;
    }
  }
}
</style>
